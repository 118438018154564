import React from 'react';

import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import DefaultImage from '../../assets/images/single-article-visu-desktop.png';
import { IconLectureTime, HorizontalYellowLineShape, ArrowIconGoRight } from '../../Atoms/Icons/Icons';
import SocialShare from '../../Molecules/SocialShare/SocialShare';
import './ArticleHighlight.scss';

let classNames = require('classnames');

const ArticleHighlight = ({ title, reading_time, linkTo, image }) => {
  const image_sources = [
    image?.childImageSharp.mobile,
    {
      ...image?.childImageSharp.desktop,
      media: '(min-width: 1023px)',
      aspectRatio: 1.542997543
    },
  ];

  return (
    <div className="article_highlight">
      <div className="single_article">
        <div className="info_share">
          <p className="lecture_time">
            <IconLectureTime className="time_icon" />
            <span>{reading_time}11</span>
            <HorizontalYellowLineShape className="bottom_line" />
          </p>
          <SocialShare
            title={title}
            linkTo={linkTo}
            hidemobile={true}
            vertical={true}
          />
        </div>
        <div className={classNames('visuel', { 'default_visuel': !image })}>
          {image ? (
            <Img
              style={{ height: '100%', width: '100%' }}
              fluid={image_sources}
            />
          ) : (
            <img src={DefaultImage} alt="" />
          )}
        </div>
        <div className="info">
          <p>
            <Link to={linkTo}>
              {title} <ArrowIconGoRight fill="#ffffff" />
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

ArticleHighlight.propTypes = {
  reading_time: PropTypes.string,
  title: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
  image: PropTypes.object,
  imageMobile: PropTypes.object
};

export default ArticleHighlight;
