import React, { useState } from 'react';

import { Button } from '../../Molecules/Button/Button';
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import TitleSection from '../../Molecules/TitleSection/TitleSection';
import { getImage, getReOrderedCarouselSlides } from '../../Utils/Utils';
import ArticleHighlight from '../ArticleHighlight/ArticleHighlight';
import Carousel from '../Carousel/Carousel';
import './HomeNews.scss';

let classNames = require('classnames');

const HomeNews = ({ newsNode, topNews, imagesArray }) => {
  let slides = [];
  let article_list_mobile = [];

  const showMoreVal = 3;
  const [showItem, setShowItem] = useState(showMoreVal);

  topNews.forEach((item) => {
    let visuel = '';

    visuel = getImage(imagesArray, item?.node?.relationships?.field_image?.drupal_internal__mid);

    slides.push({
      image: visuel,
      info: {
        text: item.node?.title,
        link: '/' + item.node?.path?.langcode + item.node?.path?.alias
      },
      reading_time: item.node?.fields?.readingTime?.format //'2\'31\'\'',
    });

    article_list_mobile.push({
      image: visuel,
      title: item.node?.title,
      link: '/' + item.node?.path?.langcode + item.node?.path?.alias,
      reading_time: item.node?.fields?.readingTime?.format //'2\'31\'\'',
    });
  });

  const onClickShowMore = () => {
    if (showItem < article_list_mobile?.length) {
      setShowItem(showItem + showMoreVal);
    }
  };

  return (
    <section className={classNames('home_news', 'section_content')}>
      <ShapeBackground
        top="right"
        color="catskill_white"
        bottomInfinite={true}
      >
        <div className="wrapper_page_xs">
          <TitleSection
            title={newsNode.field_title?.processed}
            subtitle={newsNode.body?.processed}
            type="arrow"
          />
        </div>
        <div className={classNames('wrapper_page_xs', 'only_desktop')}>
          <Carousel slides={getReOrderedCarouselSlides(slides)} sliderMode="articles" />
          <div className="btn_container">
            <Button
              primary={true}
              label={newsNode.field_link?.title}
              link={newsNode.field_link?.url}
            />
          </div>
        </div>

        <div className={classNames('wrapper_page', 'only_mobile_tablet', 'cross_wrapper_extreme_right')}>
          {article_list_mobile.map((item, i) => (
            <div className={classNames(i < showItem ? 'show-item' : 'hide-item')} key={i}>
              <ArticleHighlight
                reading_time={item.reading_time}
                title={item.title}
                image={item.image}
                linkTo={item.link}
              />
            </div>
          ))}

          <div className="btn_container">
            <Button
              primary={true}
              label={newsNode.field_link.title}
              //link={newsNode.field_link.uri}
              onClick={onClickShowMore}
              customClass={showItem >= article_list_mobile?.length ? 'hide' : ''}
            />
          </div>

        </div>
      </ShapeBackground>
    </section>
  );
};
export default HomeNews;
