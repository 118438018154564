import * as React from "react";
export const QuoteLeftFR = ({ fill }) => {
  const newfill = fill === "" ? "#FFED00" : fill;
  return (
    <svg
      id="Group_1811"
      data-name="Group 1811"
      xmlns="http://www.w3.org/2000/svg"
      width="44.242"
      height="59.154"
      viewBox="0 0 44.242 59.154"
    >
      <path
        id="Path_1914"
        data-name="Path 1914"
        d="M625.585,289.349v-.007a2.45,2.45,0,0,1,0-2.954v-.007s9.155-12.987,9.672-13.75c2.983-4.392,1.773-10.692-1.9-14.708l-21.308,28.709v0a2.565,2.565,0,0,0,0,2.46v0l14.739,18.333a11.08,11.08,0,0,0,1.074-13.979C627.345,292.69,625.585,289.349,625.585,289.349Z"
        transform="translate(-592.695 -257.923)"
        fill={newfill}
      />
      <path
        id="Path_1915"
        data-name="Path 1915"
        d="M611.709,289.151v-.007a2.45,2.45,0,0,1,0-2.954v-.007s9.155-12.987,9.672-13.75a11.078,11.078,0,0,0-1.074-13.978l-22.135,27.979v0a2.565,2.565,0,0,0,0,2.46v0l22.135,27.979a11.078,11.078,0,0,0,1.074-13.978C620.864,302.138,611.709,289.151,611.709,289.151Z"
        transform="translate(-597.858 -257.725)"
        fill={newfill}
      />
    </svg>
  );
};

export const QuoteRightFR = ({ fill }) => {
  const newfill = fill === "" ? "#FFED00" : fill;
  return (
    <svg
      id="Group_1812"
      data-name="Group 1812"
      xmlns="http://www.w3.org/2000/svg"
      width="44.242"
      height="59.154"
      viewBox="0 0 44.242 59.154"
    >
      <path
        id="Path_1916"
        data-name="Path 1916"
        d="M651.388,283.035v.007a2.45,2.45,0,0,1,0,2.954V286s-9.154,12.987-9.672,13.75c-2.983,4.392-1.771,10.692,1.9,14.708l21.308-28.709v0a2.565,2.565,0,0,0,0-2.46v0l-14.739-18.333a11.08,11.08,0,0,0-1.074,13.979C649.63,279.694,651.388,283.035,651.388,283.035Z"
        transform="translate(-640.036 -255.307)"
        fill={newfill}
      />
      <path
        id="Path_1917"
        data-name="Path 1917"
        d="M665.347,285.651v.007a2.45,2.45,0,0,1,0,2.954v.007s-9.154,12.987-9.672,13.75a11.076,11.076,0,0,0,1.076,13.978l22.134-27.979v0a2.565,2.565,0,0,0,0-2.46v0l-22.134-27.979a11.076,11.076,0,0,0-1.076,13.978C656.194,272.664,665.347,285.651,665.347,285.651Z"
        transform="translate(-634.957 -257.923)"
        fill={newfill}
      />
    </svg>
  );
};

export const QuoteLeftEn = ({ fill }) => {
  const newfill = fill === "" ? "#FFED00" : fill;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.344"
      height="41.717"
      viewBox="0 0 28.344 41.717"
    >
      <g id="Group_1824" data-name="Group 1824" transform="translate(0 0)">
        <path
          id="Path_1942"
          data-name="Path 1942"
          d="M616.428,327.414l15.78-.13c.4,4.841-2.219,8.454-6.865,8.454-1.536,0-1.789,1.17-1.789,2.027,0,0,.059,12.381.037,13-.167,4.642-4.59,8.772-9.259,9.705,0,0-.161-31-.161-31.032C614.171,328,615.184,327.512,616.428,327.414Z"
          transform="translate(-603.903 -318.752)"
          fill={newfill}
        />
        <path
          id="Path_1943"
          data-name="Path 1943"
          d="M608.554,321.173l10.4-.107c0,4.706-1.138,6.85-4.584,7-.893,0-1.129.973-1.129,1.658,0,0,.048,4.156.03,4.668a8.229,8.229,0,0,1-6.452,8.02s-.133-19.546-.133-19.572C606.688,321.66,607.525,321.254,608.554,321.173Z"
          transform="translate(-606.688 -321.066)"
          fill={newfill}
        />
      </g>
    </svg>
  );
};

export const QuoteRightEn = ({ fill }) => {
  const newfill = fill === "" ? "#FFED00" : fill;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.344"
      height="41.717"
      viewBox="0 0 28.344 41.717"
    >
      <g id="Group_1825" data-name="Group 1825" transform="translate(0 0)">
        <path
          id="Path_1944"
          data-name="Path 1944"
          d="M658.019,341.268l-15.78-.13c-.4,4.841,2.219,8.454,6.865,8.454,1.535,0,1.788,1.17,1.788,2.027,0,0-.059,12.381-.036,13,.166,4.642,4.589,8.772,9.259,9.705,0,0,.161-31,.161-31.032C660.276,341.857,659.262,341.366,658.019,341.268Z"
          transform="translate(-642.2 -332.606)"
          fill={newfill}
        />
        <path
          id="Path_1945"
          data-name="Path 1945"
          d="M664.318,335.028l-10.4-.108c0,4.706,1.138,6.85,4.584,7,.892,0,1.129.973,1.129,1.658,0,0-.049,4.156-.03,4.668a8.229,8.229,0,0,0,6.452,8.02s.133-19.546.133-19.572C666.184,335.514,665.346,335.108,664.318,335.028Z"
          transform="translate(-637.84 -334.92)"
          fill={newfill}
        />
      </g>
    </svg>
  );
};
