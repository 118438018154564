import React from 'react';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import { Button } from '../../Molecules/Button/Button';
import TitleSection from '../../Molecules/TitleSection/TitleSection';
import { getImage } from '../../Utils/Utils';
import SliderComponent from '../SliderComponent/SliderComponent';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HomeColasInnov.scss';

let classNames = require('classnames');

const HomeColasInnov = ({ innovationNode, imagesArray }) => {
  const slides = [];

  innovationNode.relationships.field_slides.forEach((item) => {
    let imageDesktop = '';
    try {
      imageDesktop = getImage(imagesArray, item?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp;
    }
    catch (e) {
      console.log(e);
    }

    item.field_publish && slides.push({
      image: imageDesktop,
      info: {
        text: item.field_title?.processed,
        link: item.field_link?.url,
        link_title: item.field_link?.title
      }
    });
  });

  const settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '100px',
    slidesToShow: 1.5,
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1.05,
        dots: true
      }
    }]
  };

  return (
    <section className={classNames('section_colas_innov', 'section_content')}>
      <div className="wrapper_page">
        <TitleSection
          title={innovationNode.field_title?.processed}
          description={innovationNode.body?.processed?.replace(/<p[^>]*>/g, '')}
          type="line"
        />
        <div className={classNames('cross_wrapper_extreme_right', 'only_desktop')}>
          <SliderComponent settings={settings}>
            {slides.map((slide, i) => (
              <div key={i}>
                {slide?.image &&
                  <Img
                    style={{ width: '100%' }}
                    imgStyle={{ objectFit: 'cover' }}
                    fluid={{ ...slide?.image?.fluid, aspectRatio: 1.77254902 }}
                  />
                }
                <div className="info_bleu_box">
                  <p>{slide.info.text}</p>
                  {slide.info.link && (
                    <Button
                      primary={true}
                      label={slide?.info?.link_title}
                      link={slide?.info?.link}
                    />
                  )}
                </div>
              </div>
            ))}
          </SliderComponent>
        </div>
      </div>
      <div className="only_mobile">
        <SliderComponent settings={settings}>
          {slides.map((slide, i) => (
            <div key={i}>
              {slide?.image &&
                <Img
                  style={{ width: '100%' }}
                  imgStyle={{ objectFit: 'cover' }}
                  fluid={{ ...slide?.image?.fluid, aspectRatio: 0.733333333 }}
                />
              }
              <div className="info_bleu_box">
                <p>{slide.info.text}</p>
                {slide.info.link && (
                  <Button
                    primary={true}
                    label={slide?.info?.link_title}
                    link={slide?.info?.link}
                  />
                )}
              </div>
            </div>
          ))}
        </SliderComponent>
      </div>
    </section>
  );
};

HomeColasInnov.propTypes = {
  innovationNode: PropTypes.object.isRequired,
  imagesArray: PropTypes.array.isRequired
};

export default HomeColasInnov;
