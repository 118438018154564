import React, { useState, useEffect, useRef } from 'react';

import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import { HorizontalYellowLineShape } from '../../Atoms/Icons/Icons';
import { Button } from '../../Molecules/Button/Button';
import './HomeOurActivities.scss';
import TitleSection from '../../Molecules/TitleSection/TitleSection';
import { getImage } from '../../Utils/Utils';

let classNames = require('classnames');

const HomeOurActivities = ({ activitiesAllDetails, imagesArray }) => {
  const [active, setActive] = useState(0);
  const [loop, setLoop] = useState();
  const AllActivities = activitiesAllDetails;
  const menu_our_activities = [];
  const timerDelay = 2500;
  const j = useRef(0);

  useEffect(() => {
    setLoop(setInterval(function () {
      setActive(j.current);
      if (j.current == menu_our_activities.length - 1) {
        j.current = -1;
      }
      j.current++;
    }, timerDelay));

    return () => clearInterval(loop);
  }, []);

  AllActivities.relationships?.field_nos_activites?.forEach((item) => {
    menu_our_activities.push(
      {
        title: item.title,
        class: 'transport',
        link: '/' + item.path?.langcode + item.path?.alias,
        visuel: getImage(imagesArray, item?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp
      }
    );
  });

  const addActiveClass = (e, i) => {
    e.preventDefault();
    j.current = i;
    setActive(i);
    clearInterval(loop);
  };

  return (
    <section className={classNames('home_our_activities', 'section_content')}>
      <div className="wrapper_page_xs">
        <TitleSection
          title={AllActivities.field_title.processed}
          subtitle={AllActivities.body.processed}
          type="arrow"
        />
      </div>
      <div className="wrapper_our_activities">
        <div className="menu">
          {menu_our_activities.map((menu_our_activities, i) => (

            <div className="menu-item" key={i}>
              <a href="#" className={classNames({ 'active': active === i })} onClick={e => addActiveClass(e, i)}>
                <span>{menu_our_activities.title}</span>
                {
                  active === i && (
                    <HorizontalYellowLineShape />
                  )
                }
              </a>
            </div>
          ))}
        </div>
        <div className="visuel_text_container">
          {menu_our_activities.map((menu_our_activities, j) => (
            <div className={classNames('visuel_text', { 'show': (active === j) })} key={j}>
              <div className="visuel">
                <div className="only_desktop">
                  {menu_our_activities?.visuel &&
                    <Img
                      style={{ width: '100%' }}
                      imgStyle={{ objectFit: 'contain' }}
                      fluid={menu_our_activities.visuel.fluid}
                    />
                  }
                </div>
                <div className="only_mobile_tablet">
                  {menu_our_activities?.visuel &&
                    <Img
                      style={{ height: '100%', width: '100%' }}
                      imgStyle={{ objectFit: 'cover' }}
                      fixed={menu_our_activities.visuel.mobile}
                    />
                  }
                </div>
              </div>
              <div className="text">
                <h1>
                  <Link to={menu_our_activities.link}>
                    {menu_our_activities.title}
                  </Link>
                </h1>
                <Button
                  label={AllActivities.field_link_title}
                  size='medium'
                  link={menu_our_activities.link}
                  customClass="test"
                  target="_blank"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

HomeOurActivities.propTypes = {
  activitiesAllDetails: PropTypes.object.isRequired,
  imagesArray: PropTypes.array.isRequired
};

export default HomeOurActivities;
