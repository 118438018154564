import React from "react";
import PropTypes from "prop-types";

import {
  QuoteLeftFR,
  QuoteLeftEn,
  QuoteRightFR,
  QuoteRightEn,
} from "./QuoteMarkIcons/QuoteMarkIcons";

import "./QuoteMark.scss";

var classNames = require('classnames');

const QuoteMark = ({ lang, size, quoteColor, text, tag, name, textColor, textWeight }) => {
  let quoteFill = "";
  switch (quoteColor) {
    case "dark-blue":
      quoteFill = "#2C4965";
      break;
    case "light-blue":
      quoteFill = "#A0C8d8";
      break;
    case "magenta-pink":
      quoteFill = "#A4186D";
      break;
    default:
      quoteFill = "#FFED00";
      break;
  }
  return (
    <div className="quote-text">
      <span className="left-quote-icon">
        {lang === "fr" ? (
          <QuoteLeftFR fill={quoteFill} />
        ) : (
          <QuoteLeftEn fill={quoteFill} />
        )}
      </span>
      <div className="quaote_text_content">
        {
          tag === 'h1' ? (
            <h1 className={classNames(`quote-text-${size}`, `textweight-${textWeight}`)} dangerouslySetInnerHTML={{__html: text }}></h1>
          ) : (
            <span className={classNames(`quote-text-${size}`, `textweight-${textWeight}`)} dangerouslySetInnerHTML={{__html: text }}></span>
          )
        }
        {
          name && (
            <p className="name">{name}</p>
          )
        }
      </div>
      <span className="right-quote-icon">
        {lang === "fr" ? (
          <QuoteRightFR fill={quoteFill} />
        ) : (
          <QuoteRightEn fill={quoteFill} />
        )}
      </span>
    </div>
  );
};

QuoteMark.propTypes = {
  lang: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
  quoteColor: PropTypes.string,
  textColor: PropTypes.string,
};

QuoteMark.defaultProps = {
  //lang: "en",
  size: "m",
  textWeight: "s",
  text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ergo.",
  quoteColor: "yellow",
  textColor: "black",
};

export default QuoteMark;
