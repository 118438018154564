import React, { useRef, useState, useEffect } from 'react';

import Img from 'gatsby-image';

import { ShapeYellowArrowBanner } from '../../Atoms/Icons/Icons';
import ScrollIcon from '../../Molecules/ScrollIcon/ScrollIcon';
import { getImage, getVideo } from '../../Utils/Utils';

import './HomeBanner.scss';

const HomeBanner = ({ homeBannerNode, imagesArray, videosArray, getBannerHeight, scroll, children }) => {
  const vidRef = useRef(null);
  const bannerRef = useRef(null);

  const [bannerHeight, setBannerHeight] = useState(622);
  const is_video = homeBannerNode.field_type_de_media === 'video' ? true : false;
  let desktop_image;
  let mobile_image;
  let videoLink;

  try {
    desktop_image = getImage(imagesArray, homeBannerNode?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp;
    mobile_image = getImage(imagesArray, homeBannerNode?.relationships?.field_mobile_image?.drupal_internal__mid)?.childImageSharp;
    videoLink = getVideo(videosArray, homeBannerNode?.relationships?.field_video?.drupal_internal__mid)?.publicURL;
  }
  catch (e) {
    console.log(e);
  }

  useEffect(() => {
    if (is_video && vidRef) {
      vidRef.current.play();
      vidRef.current.addEventListener('loadeddata', () => {
        vidRef.current.play();
      }, false);
    }
    window.addEventListener('resize', setBannerHeight(bannerRef.current ? bannerRef.current.offsetHeight : 0), true);
    if (bannerRef.current) { setBannerHeight(bannerRef.current.offsetHeight) }
    return () => { document.removeEventListener('resize', setBannerHeight, true) };
  });

  useEffect(() => {
    getBannerHeight(bannerHeight);
  }, [bannerHeight]);

  return (
    <div className="home_banner" ref={bannerRef}>
      <div className="visuel">
        <div className="mask image_container">
          {is_video ? (
            <>
              <div className="desktop">
                <video ref={vidRef} loop muted controls={false} autoPlay>
                  <source src={videoLink} type="video/mp4" />
                </video>
              </div>
              {mobile_image &&
                <Img
                  className="mobile"
                  imgStyle={{ objectFit: 'cover' }}
                  object-position="50% 50%"
                  fluid={mobile_image.fluid}
                  alt="A Gatsby astronaut"
                />
              }
            </>
          ) : (
            <>
              {desktop_image &&
                <Img
                  className="desktop"
                  imgStyle={{ objectFit: 'cover' }}
                  object-position="50% 50%"
                  fluid={desktop_image.fluid}
                  alt="A Gatsby astronaut"
                />
              }
              {mobile_image &&
                <Img
                  className="mobile"
                  imgStyle={{ objectFit: 'cover' }}
                  object-position="50% 50%"
                  fluid={mobile_image.fluid}
                  alt="A Gatsby astronaut"
                />
              }
            </>
          )}
        </div>
        <ShapeYellowArrowBanner className="shape_yellow_banner_bottom" />
      </div>
      <div className="visuel_overlay">
        {children}
        {scroll &&
          <div className="scroll_icon_container">
            <ScrollIcon />
          </div>
        }
      </div>
    </div>
  );
};

export default HomeBanner;
