import * as React from "react";
import { Button } from '../../Molecules/Button/Button'
import GraphRH from '../../Molecules/GraphRH/GraphRH'
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground'

import "./HomeJoinUs.scss";
import TitleSection from '../../Molecules/TitleSection/TitleSection'

var classNames = require('classnames');

const HomeJoinUs = ({ allJoinUsDetails }) => {
  return (
    <section className={classNames('home_join_us', 'section_content')}>
      <ShapeBackground
        top="left"
        color="bleu"
      >
        <div className="wrapper_page_xs">
          <TitleSection
            title={allJoinUsDetails?.field_title?.processed}
            subtitle={allJoinUsDetails?.field_subtitle?.processed.replace(/(<([^>]+)>)/gi, "")}
            type="arrow"
            h2color="white"
            h4color="yellow"
          />
          <div className="flex_content">
            <div className="graph_rh_component">
              <GraphRH
                jobs={allJoinUsDetails.field_chiffre_offre}
                text={allJoinUsDetails.field_offre_text}
                textColor="color_white"
              />
            </div>
            <div className="text">
              <div dangerouslySetInnerHTML={{ __html: allJoinUsDetails?.body?.processed }}></div>
              <Button
                primary={true}
                label={allJoinUsDetails.field_link?.title}
                size='medium'
                link={allJoinUsDetails.field_link?.uri}
              />
            </div>
          </div>
        </div>
      </ShapeBackground>
    </section>
  )
}

export default HomeJoinUs;
