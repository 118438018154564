import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import QuoteMark from '../../../Atoms/QuoteMark/QuoteMark';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DiscoverStores from '../../../components/DiscoverStores/DiscoverStores';
import HomeBanner from '../../../components/HomeBanner/HomeBanner';
import HomeColasInnov from '../../../components/HomeColasInnov/HomeColasInnov';
import HomeJoinUs from '../../../components/HomeJoinUs/HomeJoinUs';
import HomeNews from '../../../components/HomeNews/HomeNews';
import HomeOurActivities from '../../../components/HomeOurActivities/HomeOurActivities';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';

let classNames = require('classnames');
const IndexPage = ({ data, pageContext }) => {
  const [stickyTop, setStickyTop] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [pageScrolled, setPageScrolled] = useState(false);

  const { locale } = pageContext;
  const homeNodesData = data.homeNodesData?.edges[0];
  const imagesArray = data.allImages.edges;
  const videosArray = data.allVideos.edges;
  const promotedNews = data.promotedNews.edges;
  const getBannerHeight = (bannerHeight) => {
    const headerHeight = (window.innerWidth > 1023) ? 108 : 61;
    setStickyTop(bannerHeight + headerHeight);
  };

  const handleScroll = () => {
    setScrollTop(window.pageYOffset);
  };

  const intl = useIntl();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [pageScrolled]);

  useEffect(() => {
    (scrollTop > 50) ? setPageScrolled(true) : setPageScrolled(false);
  }, [scrollTop]);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title="Colas Home Page"
        description="Description de la Home Page de Colas"
      />
      <div className={classNames('page_template', 'zero_bottom_padding')}>
        {homeNodesData?.node?.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__main_banner':
              return (
                <HomeBanner homeBannerNode={block} imagesArray={imagesArray} videosArray={videosArray} getBannerHeight={getBannerHeight} scroll={true} key={i}>
                  <div className="wrapper_page">
                    <Breadcrumb
                      page_banner={true}
                      data={{
                        currentPage: { title: intl.formatMessage({ id: 'common.goto.home' }), url: '/' },
                        locale
                      }}
                    />
                    <div className="intro">
                      <QuoteMark
                        text={block.field_title.processed}
                        tag="h1"
                        lang={locale}
                      />
                      <div dangerouslySetInnerHTML={{ __html: block.body.processed }}></div>
                    </div>
                  </div>
                </HomeBanner>
              );

            case 'block_content__block_innovation':
              return (
                <HomeColasInnov innovationNode={block} imagesArray={imagesArray} key={i} />
              );

            case 'block_content__block_slider_nos_activites':
              return (
                <HomeOurActivities activitiesAllDetails={block} imagesArray={imagesArray} key={i} />
              );

            case 'block_content__block_actualites':
              return (
                <HomeNews newsNode={block} topNews={promotedNews} imagesArray={imagesArray} key={i} />
              );

            case 'block_content__block_nous_rejoindre':
              return (
                <HomeJoinUs allJoinUsDetails={block} key={i} />
              );

            default:
              break;
          }
        })}

        <div className="discover_store_sticky" style={{ top: `calc(${stickyTop}px)` }}>
          <DiscoverStores sticky={true} smaller={pageScrolled} />
        </div>

      </div>
    </Layout>
  );
};

export const query = graphql`
  query HomePageTemplate($locale: String!, $imageID: [Int!], $videoID: [Int!]) {
    allActivitiesImages: allNodeActivites {
      edges {
        node {
          relationships {
            field_image {
              drupal_internal__mid
            }
            field_mobile_image {
              drupal_internal__mid
            }
          }
        }
      }
    }
    homeNodesData: allNodeHomepage(
      filter: {langcode: {eq: $locale}, status: {eq: true}}
    ) {
      edges {
        node {
          status
          langcode
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__main_banner {
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_type_de_media
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_mobile_image {
                      drupal_internal__mid
                    }
                    field_video {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_innovation {
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  relationships {
                    field_slides {
                      field_publish
                      field_title {
                        processed
                      }
                      field_link {
                        title
                        url
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                      }
                      langcode
                    }
                  }
                }
                ... on block_content__block_slider_nos_activites {
                  id
                  body {
                    processed
                  }
                  field_link_title
                  field_title {
                    processed
                  }
                  relationships {
                    field_nos_activites {
                      __typename
                      ... on Node {
                        ... on node__activites {
                          title
                          path {
                            alias
                            langcode
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                            }
                          }
                        }
                      }               
                    }
                  }
                }
                ... on block_content__block_actualites {
                  id
                  body {
                    processed
                  }
                  field_link {
                    title
                    uri
                    url
                  }
                  field_title {
                    processed
                  }
                  drupal_internal__id
                }
                ... on block_content__block_nous_rejoindre {
                  body {
                    processed
                  }
                  drupal_internal__id
                  field_offre_text
                  field_link {
                    title
                    uri
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                  field_chiffre_offre
                }
              }
            }
          }
        }
      }
    }
    
    promotedNews: allNodeArticle(
      filter: {promote: {eq: true}, status: {eq: true}, langcode: {eq: $locale}}
    ) {
      edges {
        node {
          id
          title
          sticky
          status
          langcode
          promote
          path {
            langcode
            alias
          }
          fields {
            readingTime {
              format
            }
          }
          relationships {
            field_image {
              drupal_internal__mid
            }
          }
        }
      }
    }
   
    allImages: allMediaImage(
      filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imageID}}
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              localFile {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                  mobile: fixed(width: 456, height: 456) {
                    ...GatsbyImageSharpFixed
                  }
                  desktop: fixed(width: 1256, height: 814) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          langcode
        }
      }
    }
    allVideos: allMediaVideo(
      filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $videoID}}
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_video_file {
              localFile {
                publicURL
              }
            }
          }
          langcode
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export default IndexPage;
